@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

* {
    box-sizing: border-box;
    user-select: text;
}


body {
     font-family: 'Dice', sans-serif;
    padding: 0;
    margin: 0;
    background-color: var(--primary-color);
    box-sizing: border-box;

}

.light {
     --background:#F9F9FA;
    --primary-color: #F6F7FC;
    --text-color: #0b0d33;
    --button-color: #767DFF;
    --sidebar-active-color: #353535;
    --sidebar-text-color: #f5f6fa;
    --right-backgroundColor: #fff;
    --inactive-color: #5e6b6e;
    --info-background: #767DFF; /* previous color #9da1e2 */
    --info-color: #fff;
    --active-border-color: #767DFF;
    --title-desc-color: #5e6b6e; /* TitleBar Component desc color */
    --border-color: #ddd;
    --card-color: #767DFF;
    --active-background: #767DFF;
    --toggle-background: #eceef9;
    --input-backgroundColor: #F6F7FC;
    --input-color: #33363b;
    --red-color: #FF6464;
    --animate-1: #eceef9;
    --animate-2: #d9ddf2;
    --landing-box:#212121;
    --sidebar-color:#fff;
    --tile-color: #fff;
    --tile-border-color:#eee;
    --tile-light-color: #f2f2f2;
    --off-white:#F9F9FA;
    --sidebar-background-active: rgba(41, 94, 255, 0.1);
    --sidebar-background-hover: rgba(41, 98, 255, 0.1);
    --sidebar-item-active:#767DFF;
    --sidebar-item-inactive: #2e3235;
    --white-color:#fff;
    --green-color:#198754;
    --light-color: #555555;
    --light-border-color:


}


input:focus {
    outline: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1, h2, h3, h4, h5, h6, p {
    padding: 0px;
    margin: 0px;
}

.rc-slider-track {
    background-color: #777 !important;
}

.dot-parent .dots {
    display: none;
}

.dot-parent .dots:hover {
    display: block;
}




.title-container{
    background: var(--white-color) ;
    border: 1px solid var(--border-color);
    box-shadow: 18px 22px 12px rgba(1,1,1,.0275);
    border-radius: 12px;
    margin-top: 0px;
    padding: 40px !important;
    margin-top: 40px;
}

.rdrDateRangePickerWrapper {
    border: 0.1px solid #eee;
}